
import { Link } from "react-scroll";

/*--------------------
* Footer Section
----------------------*/
function Footer() {
    return (
        <footer className="footer bg-slate-900 border-t border-white border-opacity-10 py-4">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 md:col-span-6 flex pb-3 md:pb-0">
                       
                    </div>
                    <div className="col-span-12 md:col-span-6 text-center md:text-right">

                    </div>
                </div>
            </div>
        </footer>
     );
}

export default Footer;
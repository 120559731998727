

/*--------------------
* Service Section
----------------------*/
function Service(props) {
    return (
        <>
            <div className="lg:col-span-6">
                <div className={props.id.main + " feature-box-01"}>
                    <div className="icon"><i className={props.id.icon}></i></div>
                    <div className="feature-content">
                        <h5>{props.title}</h5>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
    const service_list = [
        {id:{main:'bg-1', icon:'fas fa-phone-alt'}, title:'Information Security', description:'Infosec, or information security, plays a crucial role in the realm of cloud-native computing. As organizations increasingly adopt cloud-native architectures and leverage technologies such as containers, microservices, and serverless computing, the need for robust security measures becomes paramount. Infosec in the cloud-native domain involves implementing a multi-layered approach to safeguarding sensitive data, applications, and infrastructure. It encompasses various aspects such as identity and access management, data encryption, network security, vulnerability management, and threat detection and response. Cloud-native infosec also requires continuous monitoring and auditing of resources, proactive security measures during the software development lifecycle, and adherence to compliance and regulatory requirements. By prioritizing information security in a cloud-native environment, organizations can mitigate risks, enhance data protection, and ensure the integrity and availability of their systems and services.'},
        {id:{main:'bg-2', icon:'fas fa-laptop'}, title:'DevOps', description:' DevOps is a collaborative approach that combines software development and operations to streamline the delivery and maintenance of software applications. It emphasizes communication, integration, and automation throughout the software development lifecycle. By adopting DevOps practices, organizations can accelerate time-to-market, improve product quality, and enhance overall efficiency. DevOps focuses on breaking down silos between teams, promoting continuous integration and delivery, and leveraging tools and technologies to automate processes such as testing, deployment, and infrastructure provisioning. The goal is to foster a culture of collaboration, agility, and continuous improvement, enabling organizations to respond quickly to market demands and deliver value to their customers more effectively.' },
        {id:{main:'bg-3', icon:'fas fa-exclamation-triangle'}, title:'Development', description:'From building robust Python scripts for data manipulation and analysis to crafting efficient Bash scripts for system administration tasks, I enjoy harnessing the capabilities of scripting languages to streamline processes and solve complex problems. Additionally, I delve into the world of JavaScript to create interactive web applications and leverage Terraform to provision and manage cloud infrastructure. Let\'s automate, innovate, and transform together'},
        {id:{main:'bg-4', icon:'fas fa-columns'}, title:'Cloud', description:'I am a seasoned professional with extensive expertise in Amazon Web Services (AWS). Holding Multiple AWS Certifcations, I have a deep understanding of AWS services and can guide you through the intricacies of cloud computing. On this platform, you will find a wealth of resources, including comprehensive guides, tutorials, and best practices, to help you leverage AWS to its fullest potential. Whether you are a beginner seeking to understand the basics of AWS or an experienced user looking to optimize your cloud infrastructure, I provide valuable insights and recommendations to help you achieve your goals. From designing scalable and secure architectures to implementing cost-effective solutions, I am dedicated to helping you navigate the AWS ecosystem and harness the power of cloud computing for your business. Join me on this journey as we unlock the true potential of AWS together!'},
        {id:{main:'bg-5', icon:'fas fa-ruler-vertical'}, title:'AI', description:'AI Ops brings together the power of artificial intelligence and operations to optimize IT processes, automate tasks, and enhance system performance. Here, I delve into the world of AI-driven operations, exploring techniques like anomaly detection, predictive maintenance, and intelligent monitoring to drive efficiency and reliability.'},
       
    ]
    return (
        <>
           <section data-scroll-data="1" id="services" className="section services-section bg-gray" style={{ "backgroundImage": "url(img/effect/bg-effect-1.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover"}}>
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>My Services</span></h3>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
                        {
                            service_list.map((val, i)=>{
                                return <Service key={i} id={val.id} title={val.title} description={val.description} />
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
